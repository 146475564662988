import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { makeStyles, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { Alert } from '@sporkbytes/material-ui-kit-react';
import * as Yup from 'yup';
import { clientOrderRequestForm } from '@sporkbytes/api-client/PublicContacts';

import FormHelperText from '../FormHelperText';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import withRecaptcha from '../withRecaptcha';

import steps from './steps';

import axios from '../../services/axios';
import { sendEvent } from '../../services/analytics';

const useStyles = makeStyles(theme => ({
	stepperButton: {
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	stepperButtonContainer: {
		marginBottom: theme.spacing(1),
	},
}));

const ClientOrderRequestForm = ({ state, dispatch, getRecaptchaToken }) => {
	const { activeStep, contactData, contactFound } = state;
	const [stepError, setStepError] = useState();
	const classes = useStyles();
	const data = useStaticQuery(graphql`
		query GetClientOrderRequestForm {
			allSporkLocation {
				nodes {
					id
					name
				}
			}
		}
	`);

	const ActiveStep = steps[activeStep];
	const { onNext, validationSchema } = ActiveStep;

	const isLastStep = () => activeStep === steps.length - 1;

	const handlePrev = () => {
		const newActiveStepIndex = Math.max(activeStep - 1, 0);

		dispatch({
			type: 'setStateValue',
			payload: { key: 'activeStep', value: newActiveStepIndex },
		});

		sendEvent({
			action: 'Return to Step',
			label: steps[newActiveStepIndex].analyticsLabel,
		});
	};

	const handleNext = async (values, formikBag) => {
		try {
			if (typeof onNext === 'function') {
				await onNext(values, formikBag, state, dispatch);
			}

			if (!state.completedSteps[ActiveStep.name]) {
				dispatch({
					type: 'completeStep',
					payload: {
						name: ActiveStep.name,
					},
				});

				sendEvent({
					action: 'Complete Step',
					label: ActiveStep.analyticsLabel,
				});
			}

			dispatch({
				type: 'setStateValue',
				payload: {
					key: 'activeStep',
					value: Math.min(activeStep + 1, steps.length - 1),
				},
			});
		} catch (error) {
			setStepError(error.message);
		}
	};

	const onSubmit = async (values, formikBag) => {
		const { setSubmitting, setTouched } = formikBag;

		setStepError();

		if (!isLastStep()) {
			setTouched({});
			await handleNext(values, formikBag);
			setSubmitting(false);

			return;
		}

		try {
			const recaptchaResponse = await getRecaptchaToken();
			await clientOrderRequestForm(axios, {
				...values,
				selectedPartners: values.selectedPartners
					.map(({ id, name }) => ({ id, name }))
					.sort((partnerA, partnerB) =>
						partnerA.name.localeCompare(partnerB.name)
					),
				recaptchaResponse,
			});

			dispatch({
				type: 'setStateValue',
				payload: { key: 'formSubmitted', value: true },
			});

			sendEvent({
				action: 'Complete Step',
				label: ActiveStep.analyticsLabel,
			});

			sendEvent({
				action: 'Submit Form',
				label: 'Success',
			});
		} catch (err) {
			if (err.response) {
				setStepError(err.response.data.message);
			} else {
				setStepError(err.message);
			}
			sendEvent({
				action: 'Submit Form',
				label: 'Error',
			});
			setSubmitting(false);
		}
	};

	let initialValues = steps.reduce(
		(values, { initialValues }) => ({
			...values,
			...initialValues,
		}),
		{
			...contactData?.Contact,
			emailAddress: state.emailAddress,
			SporkLocationId:
				contactData?.mostRecentSporkLocation?.id ??
				data.allSporkLocation.nodes.length === 1
					? data.allSporkLocation.nodes[0].id
					: '',
		}
	);

	if (contactData?.Contact) {
		initialValues = { ...initialValues, ...contactData.Contact };
	}

	let header;
	let helperText;

	if (contactFound) {
		header = `Welcome back, ${contactData.Contact.firstName}!`;
		helperText =
			"Please fill out the remaining parts of this form and we'll get you another meal.";
	} else {
		header = 'Welcome!';
		helperText =
			"It looks like we didn't find you in our system. Please fill out this form and we'll send you a proposal for your meal.";
	}

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validationSchema={() =>
				validationSchema
					? validationSchema(state)
					: Yup.object().shape({})
			}
		>
			{({ isSubmitting, errors, setFieldValue, values }) => (
				<>
					<Form data-testid="clientOrderRequestForm">
						<Typography variant="h3" component="h1" gutterBottom>
							{header}
						</Typography>
						<FormHelperText>{helperText}</FormHelperText>
						<Stepper activeStep={activeStep} orientation="vertical">
							{steps.map((step, index) => (
								<Step key={index}>
									<StepLabel>{steps[index].label}</StepLabel>
									<StepContent>
										<ActiveStep
											errors={errors}
											isSubmitting={isSubmitting}
											state={state}
											dispatch={dispatch}
											setFieldValue={setFieldValue}
											values={values}
										/>
										{stepError && (
											<Alert type="error">
												{stepError}
											</Alert>
										)}
										<div
											className={
												classes.stepperButtonContainer
											}
										>
											<SecondaryButton
												disabled={
													activeStep === 0 ||
													isSubmitting
												}
												onClick={handlePrev}
												className={
													classes.stepperButton
												}
											>
												Previous
											</SecondaryButton>
											<PrimaryButton
												disabled={isSubmitting}
												type="submit"
												className={
													classes.stepperButton
												}
											>
												{isLastStep()
													? 'Submit'
													: 'Next'}
											</PrimaryButton>
										</div>
									</StepContent>
								</Step>
							))}
						</Stepper>
					</Form>
				</>
			)}
		</Formik>
	);
};

ClientOrderRequestForm.propTypes = {
	state: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default withRecaptcha(ClientOrderRequestForm);
